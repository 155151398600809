.CampaignsTable {
	width: 100%;
}

.Tag {
	padding: 0.3rem 0.8rem;
	font-size: var(--scale-00);
	min-width: 1.75rem;
	text-align: center;
}
